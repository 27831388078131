import MiscService from "@/services/miscService";

// this is the vuex module that stores user and contact data that is used by many different components
export const modulePeople = {
  namespaced: true,
  state: {
    // an array containing the users that have been loaded
    users: [],
    // we are hashing the contacts, contact_id is key
    contactsHash : {},
    // a promise that will return the contacts. used so that only one api call for contacts will ever be made
    // https://tkacz.pro/use-vuex-to-avoid-multiple-requests-from-different-components/
    contactsPromise: null,
    contactTypes:[],
    familyGroups:{},
  },
  getters: {
  
    getFamilyMembers: (state) => (contact) => {
      return state.familyGroups[contact.family] || [];
    },

    getContactColor: (state) => (type_id) => {
      const defaultColor = "grey";
      try {
        const contactType = state.contactTypes.find(ct => ct.type_id === type_id);
        return contactType ? contactType.color : defaultColor;
      } catch (err) {
        console.error(err.stack);
      }
      return defaultColor;
    },
    contactTypes(state){
      return state.contactTypes;
    },
    users(state) {
      return state.users;
    },
    contactsHash(state) {
      return state.contactsHash;
    },
    user(state) {
      return (user_id) => state.users.find((user) => user.user_id === user_id);
    },
    getUserName: (state)=>(user_id)=>{
      let usr = state.users.find((user) => user.user_id === user_id);
      return usr.username;
    },
    contact: (state)=> (contact_id) =>{
      let contact = state.contactsHash[contact_id];
      return contact;
    },
    contactsPromise(state) {
      return state.contactsPromise;
    },
    // finds the contact by a phone number
    findContactByPh(state) {
      return (ph) => {
        // Replacing any non-digits with the empty string.
        let phParsed = ph.replace(/\D/g, "");
        // If the number has a 1 at the beginning, we should not consider the 1 when extracting the phone number itself.
        if (phParsed.startsWith("1")) {
          phParsed = phParsed.substring(1);
        }
        return state.contacts.find(
          (contact) => {
            // Here we are parsing each of the contact's numbers to get rid of any additional
            // characters that are not digits (Things like parentheses and dashes).
            let ph1Parsed = null;
            let ph2Parsed = null;
            let ph3Parsed = null;
            if (contact.ph1) {
              ph1Parsed = contact.ph1.replace(/\D/g, "")
            }
            if (contact.ph2) {
              ph2Parsed = contact.ph2.replace(/\D/g, "")
            }
            if (contact.ph3) {
              ph3Parsed = contact.ph3.replace(/\D/g, "")
            }
            return (ph1Parsed === phParsed || ph2Parsed === phParsed || ph3Parsed === phParsed);
          }
        );
      };
    },
    // gets the contacts full name from a contact_id
    contactName(state) {
      return (contact_id) => {
        let contact = state.contactsHash[contact_id];
        return contact
          ? (contact.first_name || "") + (contact.first_name ? " " : "") + (contact.last_name || "")
          : "";
      };
    },
    getHeaderInfo(state) {
      return (contact_id) => {
      let contact = state.contactsHash[contact_id];
      
      if(!contact){
        contact = state.users.find(o => o.user_id === parseInt(contact_id));
        console.log("if else worked for user");
      }
      return contact 
        ? {...contact, ph1: contact.ph1, ph2: contact.ph2, ph3: contact.ph3, full_name: (contact.first_name + " " + contact.last_name),language:contact.language} 
        : {phone_num: contact_id, first_name: contact_id, full_name: contact_id}
      };
    },
    getUserNames(state){
      return (userArray) => {
        let userNames = "";
        for (const user_id of userArray) {
          // console.log("seraching user"+user_id);
          let user = state.users.find(o => o.user_id === parseInt(user_id));
         
          if (user) {
            if(userNames!=""){
              userNames +=" and "
            }
            // console.log("found user :"+JSON.stringify(user));
            userNames += user.first_name + " " + user.last_name;
          } else{
            // console.log("user not found :"+user_id);
          }
        }
        // console.log("the username returned is:"+userNames +" for userArray :"+userArray);
        return userNames;
      };
    },
  },
  mutations: {
    users(state, value) {
      state.users = value;
    },
    contacts(state, value) {
      value.forEach(contact => {
        if(contact.contact_id){ 
          state.contactsHash[contact.contact_id] = contact; // Assuming 'id' is the unique identifier
        }
      });
    },

    familyGroups(state, value) {
      state.familyGroups = value;
    },

    contactsPromise(state, value) {
      state.contactsPromise = value;
    },
    contactTypes(state,value){
      state.contactTypes=value;
    },
    add_ContacTypes(state,value){
      state.contactTypes.push(value);
  },
  update_ContactTypes(state,value){
     const index = state.contactTypes.findIndex(ct => ct.type_id === value.type_id);
     if (index !== -1)
        state.contactTypes.splice(index, 1, value);
  },
  add_update_contacts(state,value){
      state.contactsHash[value.contact_id]= value;
  },
  delete_contact(state,contactId){
    if (state.contactsHash[contactId]) {
      delete state.contactsHash[contactId];
    } else {
      console.error(`Contact with ID ${contactId} does not exist.`);
    }
  }
  },
  actions: {
    async getUser({ dispatch, getters }, payload) {
      try {
        if (!payload) throw new Error("no user_id given");
        let user = getters.user(payload);
        if (user) return user;

        await dispatch("getUsers");
        user = getters.user(payload);
        if (user) return user;
        throw new Error("users were not set properly in the vuex people module");
      } catch (err) {
        throw new Error(err);
      }
    },
    async getContactTypes({ commit }) {
        let res = await MiscService.getContactTypes();
        console.log("setting contact_types,",res.data);
        commit("contactTypes", res.data);
    },
    async setContactType({ commit },payload) {
      let res = await MiscService.setContactType(payload.contact_id,payload.type_id);
      console.log("updating contact,",res.data);
      // it will update in all area through web socket
  },
    getUsers({ commit }) {
      return new Promise((resolve, reject) => {
        MiscService.getUsers()
          .then((res) => {
            commit("users", res.data);
            return resolve();
          })
          .catch((e) => reject(e));
      });
    },
    async getContact({ dispatch, getters }, payload) {
      try {
        if (!payload) throw new Error("no contact_id given");
        let contact = getters.contact(payload);
        if (contact) return contact;
        if (!contact && getters.contacts.length) throw new Error("no contact found");

        await dispatch("getContacts");
        contact = getters.contact(payload);
        if (contact) return contact;
        throw new Error("contacts were not set properly in the vuex people module");
      } catch (err) {
        throw new Error(err);
      }
    },
    async getContacts({ commit, getters }) {
      try {
        // do not get contacts if they are already there
        if (getters.contacts && getters.contacts.length) return;
        // if the contacts have already been requested and we are waiting for a response
        if (getters.contactsPromise) return getters.contactsPromise;

        let promise = MiscService.getContacts().then((res) => {
          commit("contactsPromise", null);
          commit("contacts", res.data);

          // Group contacts by family
          let contactsHash = res.data;
          let familyGroups = []; 
          Object.values(contactsHash).forEach((contact) => {
            if (contact.family && contact.family !== 0) { 
              if (!familyGroups[contact.family]) {
                familyGroups[contact.family] = [];
              }
              familyGroups[contact.family].push(contact.contact_id);
            }
          });
          commit("familyGroups", familyGroups);
        });

        commit("contactsPromise", promise);
        return promise;
      } catch (err) {
        throw new Error(err);
      }
    },
  },
};
