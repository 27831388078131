import http from "../http-common";
import axios from "axios";


class nonloginService {
  sendmessage = (data) =>  {return axios.post(process.env.VUE_APP_API_URL +`/nl/postMessage`, {data});}
  postmessage = (data) =>  {return axios.post(process.env.VUE_APP_API_URL +`/nl/postMessage`, {data});}
  getClientPages = () =>  {return axios.get(process.env.VUE_APP_API_URL +`/nl/getClientPages`);}
  clientPages = () =>  {return axios.get(process.env.VUE_APP_API_URL +`/nl/clientPages`);}
  sendvideo = (cleintid, formData) => {return axios.post(process.env.VUE_APP_API_URL +`/nl/${cleintid}/sendfbvideo`,formData);}
}

export default new nonloginService();
