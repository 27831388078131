<template>
  <div style="flex: 1; display: flex; flex-direction: column" class="mx-2">
    <div style="flex: 1">
      <div>
        <v-file-input
          ref="uploader"
          accept="image/png, image/jpeg, image/bmp, image/gif, application/pdf, video/mp4"
          label="Upload Files"
          variant="underlined"
          multiple
          show-size
          @change="onFileChanged"
          truncate-length="15"
        ></v-file-input>
      </div>
      <v-menu
        v-if="call && call.contact_id"
        :model-value="
          toggleMention && filteredUsers.length > 0 && !disablePopup
        "
        max-width="200"
        scroll-strategy="none"
        location="top"
        :offset="[0, -caretPosition.left]"
      >
        <template v-slot:activator="{ props }">
          <div>
            <v-textarea
              v-bind="props"
              v-model="message"
              @click:append="reminderClicked()"
              @focus="focused = true"
              @blur="focused = false"
              @keyup="calculateMention"
              @keydown="checkKeyPressed"
              :disabled="(call && !call.contact_id) || disableTextarea"
              rows="1"
              append-icon="mdi-alarm"
              auto-grow
              filled
              rounded
              variant="solo-filled"
              placeholder="Type message"
              ref="textarea"
              id="vtextarea"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="toggleTranslate" :color="iconcolor">{{
                  translateIcon
                }}</v-icon>
              </template>
              <template v-slot:append-inner>
                <v-icon
                  :style="sendStyle"
                  @click="messageSent()"
                  @mouseup="sendStyle = 'margin-right: 5px'"
                  @mousedown="
                    sendStyle = 'margin-right: 5px; background-color: #d3d3d3'
                  "
                  :color="focused ? 'primary' : 'dark'"
                >
                  mdi-send
                </v-icon>
                <v-icon
                  v-if="deviceIcons.length == 1"
                  :color="focused ? 'primary' : 'dark'"
                >
                  {{ deviceTypeIcon }}
                </v-icon>
                <v-menu
                  open-on-hover
                  top
                  offset-x
                  max-width="60"
                  v-else-if="deviceIcons.length >= 1"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon
                      v-bind="props"
                      :color="focused ? 'primary' : 'dark'"
                    >
                      {{ deviceTypeIcon }}
                    </v-icon>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="newDeviceClicked(0)"
                      :style="recentNumberBackground(0)"
                    >
                      <v-tooltip location="top" :text="calcRecommendedPhone(0)">
                        <template v-slot:activator="{ props }">
                          <v-icon
                            v-bind="props"
                            :color="
                              deviceTypeIcon == deviceIcons[0].icon
                                ? 'primary'
                                : 'dark'
                            "
                            >{{ deviceIcons[0].icon }}</v-icon
                          >
                        </template>
                      </v-tooltip>
                    </v-list-item>
                    <v-list-item
                      @click="newDeviceClicked(1)"
                      v-if="deviceIcons.length >= 2"
                      :style="recentNumberBackground(1)"
                    >
                      <v-tooltip location="top" :text="calcRecommendedPhone(1)">
                        <template v-slot:activator="{ props }">
                          <v-icon
                            v-bind="props"
                            :color="
                              deviceTypeIcon == deviceIcons[1].icon
                                ? 'primary'
                                : 'dark'
                            "
                            >{{ deviceIcons[1].icon }}</v-icon
                          >
                        </template>
                      </v-tooltip>
                    </v-list-item>
                    <v-list-item
                      @click="newDeviceClicked(2)"
                      v-if="deviceIcons.length == 3"
                      :style="recentNumberBackground(2)"
                    >
                      <v-tooltip location="top" :text="calcRecommendedPhone(2)">
                        <template v-slot:activator="{ props }">
                          <v-icon
                            v-bind="props"
                            :color="
                              deviceTypeIcon == deviceIcons[2].icon
                                ? 'primary'
                                : 'dark'
                            "
                            >{{ deviceIcons[2].icon }}</v-icon
                          >
                        </template>
                      </v-tooltip>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-textarea>
          </div>
        </template>
        <RecycleScroller
          v-show="toggleMention && filteredUsers.length > 0 && !disablePopup"
          :items="filteredUsers"
          :minItemSize="25"
          :item-size="45"
          key-field="user_id"
          v-slot="{ item }"
          class="text-caption mention"
        >
          <v-hover v-slot="{ hover }">
            <div
              @click="userSelected(item)"
              style="display: flex; align-items: center; height: 45px"
              class="pl-2"
              :class="hover ? 'highlighted' : ''"
            >
              <UserPopup noMenu dense :user_id="item.user_id" style="flex: 1" />
              <div
                style="
                  flex: 4;
                  display: flex;
                  flex-direction: column;
                  height: 45px;
                  justify-content: center;
                "
              >
                <span style="height: 15px; font-size: 13px" class="ml-2">{{
                  item.username
                }}</span>
                <span style="font-size: 9px" class="ml-2">{{
                  item.first_name + " " + item.last_name
                }}</span>
              </div>
            </div>
          </v-hover>
        </RecycleScroller>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PlivoService from "@/services/plivoService";
import { phoneFormat } from "@/util/util";
import UserPopup from "@/components/Avatar/UserPopup";
// Will use this function from textarea-caret package
let getCaretCoordinates = require("textarea-caret");

export default {
  name: "FDMessengerBox",
  components: { UserPopup },
  props: {
    call: { type: Object, default: null },
    contact: { type: Object, default: null },
    mostRecentNumber: { type: String, default: null },
    lastReceivedMsg: { type: Object, default: null },
  },
  data() {
    return {
      // selectedFiles: [],
      selectedFiles: null,
      focused: false,
      sendStyle: "margin-right: 5px",
      message: "",
      mention: "",
      toggleMention: false,
      deviceIcons: [], // list of valid devices
      deviceTypeIcon: null, // currently selected device
      otherIcon1: null, // list items
      otherIcon2: null,
      deviceDialog: false,
      // This is how we will identify where to place the @mentions pop-up, using the textarea-caret package
      // we will get the values of the fields 'left' and 'top,' while we will calculate 'topAdjusted'
      // ourselves based on the value we received for 'top.' Then, we will position the v-menu using the 'left'
      // and 'topAdjusted' fields, nudging the v-menu to the right by 'left' pixels and nudging the v-menu
      // to the bottom by 'topAdjusted pixels.'
      caretPosition: { left: 0, top: 0, topAdjusted: 0 },
      currMentionStartIndex: null,
      oldTop: 5,
      edgeCaseAdjustment: 0,
      prevAdjustmentResult: 3,
      textAreaHeight: null,
      mentionLength: -1,
      // This will be an object array that will hold all the mentions,
      // Each object will have the following fields:
      // 2) starting index of mention
      // 3) ending index of mention
      mentions: [],
      // Will hold the index of all the mentions that need to have their starting and
      // ending index update due to the message text either increasing or decreasing in
      // size.
      mentionIndicesToUpdate: [],
      disableTextarea: false, //prolly get rid of this!
      cursorOverMention: false,
      translateIcon: "mdi-translate",
      basedOnLastMsg: true,
      iconcolor: "grey",
    };
  },
  computed: {
    ...mapGetters(["userId"]),
    ...mapGetters("people", ["users"]),
    ...mapGetters("frontdesk",["odFilterSelectedValue","getOdTag"]),
    /*iconcolor(){
      return this.computeIconColor();
    },*/
    isSpanishContact() {
      console.log(" the language is: " + JSON.stringify(this.contact));
      return this.contact.language === "es";
    },
    // filters the users based on the mention text
    filteredUsers: function () {
      if (!this.users) {
        return [];
      } else {
        let result = this.users.filter((user) => {
          if (user.last_name) {
            const fullName = user.first_name + " " + user.last_name;
            return (
              user.username
                .toLowerCase()
                .startsWith(this.mention.toLowerCase()) ||
              fullName.toLowerCase().startsWith(this.mention.toLowerCase()) ||
              user.last_name
                .toLowerCase()
                .startsWith(this.mention.toLowerCase())
            );
          } else {
            return (
              user.username
                .toLowerCase()
                .startsWith(this.mention.toLowerCase()) ||
              user.first_name
                .toLowerCase()
                .startsWith(this.mention.toLowerCase())
            );
          }
        });

        let mentionSplit = this.mention.split(" ");
        //console.log("cursor index: "+this.cursorIndex());
        //console.log("mention split length: "+mentionSplit[0].length);
        //console.log("currMentionStartIndex: "+this.currMentionStartIndex);
        let mentionIndex =
          parseInt(this.currMentionStartIndex) +
          parseInt(mentionSplit[0].length);
        //console.log("mention index: "+mentionIndex);
        if (result.length > 0 || this.cursorIndex() > mentionIndex) {
          return result;
        } else {
          return this.users.filter((user) => {
            //console.log(mentionSplit);
            if (user.last_name) {
              const fullName = user.first_name + " " + user.last_name;
              return (
                user.username
                  .toLowerCase()
                  .startsWith(mentionSplit[0].toLowerCase()) ||
                fullName
                  .toLowerCase()
                  .startsWith(mentionSplit[0].toLowerCase()) ||
                user.last_name
                  .toLowerCase()
                  .startsWith(mentionSplit[0].toLowerCase())
              );
            } else {
              return (
                user.username
                  .toLowerCase()
                  .startsWith(mentionSplit[0].toLowerCase()) ||
                user.first_name
                  .toLowerCase()
                  .startsWith(mentionSplit[0].toLowerCase())
              );
            }
          });
        }
      }
    },
    showVMenu: function () {
      return (
        this.toggleMention &&
        this.filteredUsers.length > 0 &&
        !this.disablePopup
      );
    },
  },
  mounted() {
    this.setIcons();
  },
  watch: {
    call: function () {
      this.setIcons();
    },
    message: function () {
      if (this.message == "") this.calculateMention();
    },
    lastReceivedMsg: function () {
      console.log("The last revei msg is called in watch");
      this.iconcolor = this.computeIconColor();
    },
    showVMenu: function () {
      if (this.showVMenu == false) {
        this.edgeCaseAdjustment = 0;
      }
    },
    mention: function () {
      if (this.filteredUsers.length == 1 && this.toggleMention) {
        let fullName;
        if (this.filteredUsers[0].last_name) {
          fullName =
            this.filteredUsers[0].first_name.toLowerCase() +
            " " +
            this.filteredUsers[0].last_name.toLowerCase();
        } else {
          fullName = null;
        }
        if (
          this.filteredUsers[0].username.toLowerCase() ==
            this.mention.toLowerCase() ||
          this.filteredUsers[0].first_name.toLowerCase() ==
            this.mention.toLowerCase() ||
          fullName == this.mention.toLowerCase()
        ) {
          this.userSelected(this.filteredUsers[0]);
        }
      }
    },
  },
  methods: {
    computeIconColor() {
      console.log(
        "The last reveidmsg in method is:" +
          JSON.stringify(this.lastReceivedMsg)
      );
      let color = "grey";
      if (this.basedOnLastMsg && this.lastReceivedMsg) {
        console.log("inside compute :" + JSON.stringify(this.lastReceivedMsg));
        if (this.lastReceivedMsg.src_language === "es") {
          color = "blue";
        } else {
          color = "grey";
        }
      } else {
        this.contact.language === "es" ? (color = "blue") : (color = "grey");
      }
      console.log("returning icon color as:" + color);
      return color;
    },
    // adds a message to the convo
    calcRecommendedPhone(index) {
      let str = phoneFormat(this.deviceIcons[index].num);
      if (this.deviceIcons[index].num == this.mostRecentNumber)
        str += "\nRecommended Phone";
      return str;
    },
    setIcons() {
      if (this.call == undefined) return;

      this.deviceIcons = [];
      this.deviceTypeIcon = null;

      if (this.call.ph1 && this.call.textable1) {
        this.deviceIcons.push({ icon: "mdi-cellphone", num: this.call.ph1 });
        if (this.call.ph1 == this.mostRecentNumber)
          this.deviceTypeIcon = "mdi-cellphone";
      }
      if (this.call.ph2 && this.call.textable2) {
        this.deviceIcons.push({ icon: "mdi-home", num: this.call.ph2 });
        if (this.call.ph2 == this.mostRecentNumber)
          this.deviceTypeIcon = "mdi-home";
      }
      if (this.call.ph3 && this.call.textable3) {
        this.deviceIcons.push({ icon: "mdi-briefcase", num: this.call.ph3 });
        if (this.call.ph3 == this.mostRecentNumber)
          this.deviceTypeIcon = "mdi-briefcase";
      }

      if (this.deviceTypeIcon == null && this.deviceIcons.length > 0)
        this.deviceTypeIcon = this.deviceIcons[0].icon;
    },
    recentNumberBackground(index) {
      return this.deviceIcons[index].num == this.mostRecentNumber
        ? "background-color: #d3d3d3"
        : "";
    },
    async readFile(file, blob, encodedBlob) {
      var fr = new FileReader();
      fr.onload = async function () {
        var data = fr.result;
        console.log(data);
        blob = new Int8Array(data);
        encodedBlob = btoa(blob);
      };
      fr.readAsArrayBuffer(file);
      return encodedBlob;
    },
    async messageSent() {
      var dest = "";

      // non-contact
      if (this.call.phone_num) dest = this.call.phone_num;
      else {
        // choses the destination number based on the icon chosen by user.
        let index = this.deviceIcons.findIndex(
          (element) => element.icon == this.deviceTypeIcon
        );

        if (index == -1) return;
        dest = this.deviceIcons[index].num;
      }

      let has_attachment = false;
      const formData = new FormData();

      if (this.selectedFiles && this.selectedFiles.length > 0) {
        has_attachment = true;
        console.log(this.selectedFiles + ":::" + this.selectedFiles.length);

        console.log("The selected files are:", this.selectedFiles);
        for (var i = 0; i < this.selectedFiles.length; i++) {
          formData.append("files", this.selectedFiles[i]);
        }
      } else if (this.message == "") {
        return;
      }
      formData.append("dst", dest);
      formData.append("message_text", this.message);
      formData.append("has_attachment", has_attachment);
      formData.append("src", "1234567890");
      if (this.call.contact_id && this.contact) {
        formData.append("translate", String(this.iconcolor === "blue"));// need to check when od filter applied it return's "true" or "false" in String format, without od filter it correctly return's boolean type.
      }
      // Applying Auto tag for the message when it is on OD filter.
      if(this.odFilterSelectedValue){
        if(this.getOdTag && this.getOdTag.enable_auto){
          const tagId = this.getOdTag.tag_id;
          formData.append("tag", tagId);
        }
      }
      console.log(this.call);
      console.log(this.contact);
      //const headers = { 'Content-Type': 'multipart/form-data' };
      PlivoService.sendMessage(formData);
      this.message = "";
      this.selectedFiles = null;
      this.$refs.uploader.reset();
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(files) {
      if (files == null) return;

      if (files.length > 8) {
        alert("Max number of files that can be attached is 8");
        this.$refs.uploader.reset();
        return;
      }
      console.log("files are:", files);
      console.log("act files are:", files.target.files);
      this.selectedFiles = files.target.files;
      console.log("selected files :", this.selectedFiles);
    },
    async toggleTranslate() {
      //do we need to have an option to stop translation
      console.log("this languate" + this.contact.language);
      console.log("The icon:" + JSON.stringify(this.$refs.textarea.$refs));
      /* if (this.contact.language === 'es') {
        let prependIcon=this.$refs.textarea.$refs["prepend-icon"]
        if (prependIcon === 'translate') {
          this.$refs.textarea.$refs["prepend-icon"] = 'translateOff'
        } else {
          this.$refs.textarea.$refs["prepend-icon"] = 'translate'
        }
        this.iconcolor = this.iconcolor === 'blue' ? 'grey' : 'blue';
      }*/
      this.iconcolor = this.iconcolor === "blue" ? "grey" : "blue";
      return;
    },
    async userSelected(user) {
      let messageFirstHalf = this.message.slice(0, this.currMentionStartIndex);
      let mentionSplit = this.mention.split(" ");
      let messageSecondHalf;
      //console.log("in user selected: "+this.mention);
      if (mentionSplit.length > 1 && mentionSplit[1].length > 0) {
        if (
          this.filteredUsers.filter((e) => {
            if (e.last_name) {
              let fullName =
                e.first_name.toLowerCase() + " " + e.last_name.toLowerCase();
              return fullName.startsWith(this.mention.toLowerCase());
            } else {
              return false;
            }
          }).length > 0
        ) {
          messageSecondHalf = this.message.slice(
            this.currMentionStartIndex + this.mention.length
          );
        } else {
          messageSecondHalf = this.message.slice(
            this.currMentionStartIndex + mentionSplit[0].length
          );
        }
      } else {
        messageSecondHalf = this.message.slice(
          this.currMentionStartIndex + mentionSplit[0].length
        );
      }

      // Adding 6 spaces here to the message to account for the extended mention ui length
      // and 1 space extra so user doesn't have to hit spacebar.
      // Sike it's just 5 spaces actually.
      // Update: Ending up just adding 1 extra space to do the additional automatic spacebar after a mention
      // for the user.
      this.message = messageFirstHalf + user.username + " " + messageSecondHalf;

      // Getting starting and ending indices of mention
      let startIndex = parseInt(messageFirstHalf.length) - 1;
      let endIndex = startIndex + parseInt(user.username.length) + 1;
      console.log("startindex: " + startIndex);
      console.log("endindex: " + endIndex);

      // Toggle mention to false as we do not need the mention popup anymore
      this.toggleMention = false;

      // Creating new mention object
      let newMention = {
        start: startIndex,
        end: endIndex,
      };

      // Adding mention to our mentions object array
      this.mentions.push(newMention);

      // Here we are temporarily disabling the popup after the mention was created
      // so that if the person's username is also the first name of the individual
      // who was just mentioned, the pop up will not keep showing after they hit the
      // spacebar (since our program will then assume they are trying to still
      // pick the person to mention by typing in their last name, when in reality
      // the user had already picked the mention).
      this.disablePopup = true;
    },
    reminderClicked() {
      this.$emit("reminderClicked");
    },
    phoneClicked() {
      this.deviceDialog = true;
    },
    newDeviceClicked(num) {
      this.deviceTypeIcon = this.deviceIcons[num].icon;
    },
    checkKeyPressed(event) {
      if (event && event.key == "@") {
        // If the key pressed was an @ symbol we can re-enable to popup to show.
        this.disablePopup = false;
      }
    },
    calculateMention(event) {
      let b = [
        ...this.message.matchAll(/\B(@([a-z0-9_-]+)( ([a-z0-9_-]+))?)|(@)/gi),
      ];
      // An array of entries for each mention in the text, where each entry is itself
      // an array of size 2, the first element indicating the starting cursor index of that
      // mention and the second element indicating the ending cursor index of that mention.
      let mentionIndices = b.map((a) => [a.index + 1, a.index + a[0].length]);

      if (mentionIndices.length == 0) return;

      let cursorIndex = event.target.selectionEnd;
      console.log(cursorIndex);

      let caret = getCaretCoordinates(event.target, cursorIndex);
      let currTextAreaHeight = event.target.style?.height || 32;

      //console.log("Curr cursor index: " + this.cursorIndex());

      // getting the current mention if the user's cursor is over a portion of text that
      // requires a mention/is an unfinished mention (will hold start index of the mention
      // as the first element, and end index as the second element, currMention is a
      // two-element array).
      const currMention = mentionIndices.findIndex(
        (a) => cursorIndex >= a[0] && cursorIndex <= a[1]
      );

      console.log("Curr mention return val: " + currMention);

      if (currMention >= 0) {
        this.currMentionStartIndex = mentionIndices[currMention][0]; //added this!
        this.mention = this.message.substring(
          mentionIndices[currMention][0],
          mentionIndices[currMention][1]
        );

        let atSymbol = getCaretCoordinates(
          event.target,
          mentionIndices[currMention][0] - 1
        );

        this.caretPosition = atSymbol;

        // edge case stuff
        if (this.textAreaHeight && this.textAreaHeight < currTextAreaHeight) {
          if (this.caretPosition.top > 33) {
            this.edgeCaseAdjustment = 25;
          } else {
            this.edgeCaseAdjustment = 10;
          }
          console.log("down");
        } else if (
          this.textAreaHeight &&
          this.textAreaHeight > currTextAreaHeight
        ) {
          this.edgeCaseAdjustment = 0;
          console.log("up");
        }

        // The 45 here is the height of each item in the RecycleScroller (the value of :itemSize prop)
        if (this.filteredUsers.length * 45 < 200) {
          this.caretPosition.topAdjusted =
            this.caretPosition.top -
            this.filteredUsers.length * 45 -
            10 -
            this.edgeCaseAdjustment; // Minus 10 for buffer, so pop-up not right on top of words.
        } else {
          this.caretPosition.topAdjusted = this.caretPosition.top - 200;
        }
        this.toggleMention = true;
      } else {
        this.toggleMention = false; // User cursor not over a mention
      }

      this.textAreaHeight = currTextAreaHeight;
      this.oldTop = caret.top;

      return mentionIndices;
    },
    // gets the cursor index in the v-textarea
    cursorIndex() {
      try {
        let index =
          this.$refs.textarea.$el.$el.children[0].children[0].children[2]
            .children[1].selectionStart;
        return index;
      } catch (e) {
        return 0;
      }
    },
    phoneFormat,
  },
};
</script>

<style scoped>
.mention {
  width: 200px;
  max-height: 200px;
  background: white;
  border: 5px solid #f0f0f0;
  text-align: left;
}
.input-none {
  display: none;
}
</style>