<template>
  <v-card class="callbar">
    <div style="margin-right: 20px;" v-if="getCallBarDisplay != 'default'">
      <CallingDisplay
        v-if="getCallBarDisplay === 'calling'"
        :displayTitle="outboundCalleeDisplayTitle"
        style="height: 100%;"
      />
      <IncomingCallDisplay
        v-else-if="getCallBarDisplay === 'incomingCall'"
        :inboundCallerDisplayTitle="inboundCallerDisplayTitle"
        :inboundCallUUID="inboundCallUUID"
        @incomingCallIgnored="handleIncomingCallIgnored"
      />
      <InCallDisplay v-else-if="getCallBarDisplay === 'inCall'" />
      <AddToCallDisplay v-else-if="getCallBarDisplay === 'addToCall'" @dismount="dismountAddToCall" />
      <ConnectedDisplay v-else-if="getCallBarDisplay === 'connected'" />
    </div>
    <DefaultDisplay
      @callPressed="defaultDisplayCall"
      @contactCallPressed="contactFullnameNumber"
      :searchMasterList="searchMasterList"
      :callEnabled="getCallBarDisplay == 'default'"
    />
  </v-card>
</template>

<script>
import EventBus from "@/eventBus";

import DefaultDisplay from "@/components/CallBar/DefaultDisplay";
import CallingDisplay from "@/components/CallBar/CallingDisplay";
import IncomingCallDisplay from "@/components/CallBar/IncomingCallDisplay";
import InCallDisplay from "@/components/CallBar/InCallDisplay";
import AddToCallDisplay from "@/components/CallBar/AddToCallDisplay";
import ConnectedDisplay from "@/components/CallBar/ConnectedDisplay";

import PlivoService from "@/services/plivoService";

import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "CallBar",
  components: {
    DefaultDisplay,
    CallingDisplay,
    IncomingCallDisplay,
    InCallDisplay,
    AddToCallDisplay,
    ConnectedDisplay,
  },
  watch: {},
  data() {
    return {
      // These variables will be passed as props to the incomingCallDisplay component.
      inboundCallerDisplayTitle: null, // String that indicates who is calling in.
      inboundCallUUID: null, // Call uuid of the inbound call to be answered (or not).
      inboundCallExtraHeaders: null, // Extra headers of the inbound call to be answered (or not).

      // This variable will be passed as a prop to the callingDisplay component.
      outboundCalleeDisplayTitle: null, // String that indicates who the user is calling.

      // Will contain the list of all people (users and contacts) and different fields describing them.
      searchMasterList: [],
      notificationsAllowed: false,
    };
  },
  computed: {
    ...mapGetters(["userId", "firstName", "lastName", "fullName"]),
    ...mapGetters("people", ["users", "user", "contactsHash", "findContactByPh"]),
    ...mapGetters("plivo", [
      "getConferences",
      "getCurrConferenceName",
      "getCurrCallUuid",
      "getCallBarDisplay",
      "getPrevCallBarDisplay",
    ]),
    contacts() { // it will give contactHash object as array format
      return Object.values(this.contactsHash);
    },
  },
  methods: {
    ...mapMutations("plivo", [
      "setCurrConferenceName",
      "setCurrCallUuid",
      "setCallBarDisplay",
      "setPrevCallBarDisplay",
    ]),
    ...mapActions("people", ["getUsers", "getContacts","getContactTypes"]),
    ...mapActions("tags", ["getTags"]),
    handleIncomingCall(callInfo, extraHeaders) {
      console.log("Inside handle incoming call - callinfo", callInfo)
      let callingUser = extraHeaders["X-Ph-Calleruserid"] ? parseInt(extraHeaders["X-Ph-Calleruserid"]) : 0;
      let phoneNumber = callInfo.src
      let fullName = this.handleDisplayName(phoneNumber)
      // try {
      //   console.log("Inside try of handleIncoming call- contacts", this.contacts)
      //   let phoneString = phoneNumber.replace(/[+]/gi, '');
      //   phoneNumber = "(" + phoneString.substring(1, 4) + ")" + phoneString.substring(4, 7) + "-" + phoneString.substring(7);
      //   let contact = this.contacts.find(c => (c.ph1 || c.ph2 || c.ph3) == phoneNumber)
      //   console.log("Handle Incoming call - contact", contact)
      //   if(contact){
      //     fullName = contact.first_name + ' ' + contact.last_name
      //     console.log("Inside handle incoming call - if contact", fullName) 
      //   }
      // } catch(err) {
      //   console.error('Handle Incoming call - format phone no',err)
      // }
      this.inboundCallerDisplayTitle = callingUser ? this.user(callingUser).username : fullName ? fullName : phoneNumber;
      this.inboundCallUUID = callInfo.callUUID;

      this.setPrevCallBarDisplay(this.getCallBarDisplay);
      this.setCallBarDisplay("incomingCall");

      // Display notification of incoming call in the browser (if allowed).
      if (this.notificationsAllowed) new Notification("Incoming call from " + this.inboundCallerDisplayTitle);
    },

    handleDisplayName(phoneNumber) {
      console.log("Inside handleDisplayName - phoneNumber - before try", phoneNumber)
      try {
        console.log("Inside try of handleDisplayName call- contacts", this.contacts)
        let fullName
        let phoneString = phoneNumber.replace(/[+]/gi, '');
        phoneNumber = phoneString.substring(1);
        let contact = this.contacts.find(c => (c.ph1 || c.ph2 || c.ph3) == phoneNumber)
        console.log("handleDisplayName - contact", contact)
        if(contact){
          fullName = contact.first_name + ' ' + contact.last_name
          console.log("Inside handleDisplayName - if contact", fullName) 
        }
        console.log("Inside handleDisplayName before return", fullName)
        return fullName
      } catch(err) {
        console.error('Handle Incoming call - format phone no',err)
      }
    },

    handleOnCalling() {
      this.setCallBarDisplay("calling");
    },
    handleOnCallTerminated() {
      if (this.getCallBarDisplay === "inCall" || this.getCallBarDisplay === "addToCall")
        this.setCallBarDisplay("default");
    },
    async handleOnCallAnswered(callInfo) {
      this.setCallBarDisplay("connected");
      if (callInfo.direction === "outgoing") {
        // Muting user while automated message is played.
        // Note: No need to mute for inbound calls because before even the call starts ringing to our
        // endpoints, we play the automated message using the PHLO.
        //this.plivoBrowserSdk.client.mute();
      }
    },
    handleOnCallFailed() {
      this.setCallBarDisplay("default");
    },
    handleOnIncomingCallCanceled() {
      this.setCallBarDisplay(this.getPrevCallBarDisplay);
    },
    handleOnCallRemoteRinging() {
      console.log('ringing ringing ringing')
    },
    handleCallEvent(callData) { // Handler for the $emit call-event
      console.log("handleCallEvent", callData.name, callData.number,callData.tagId)
      if (callData.name){ 
          this.outboundCalleeDisplayTitle = callData.name;
      }else{ 
          this.outboundCalleeDisplayTitle = callData.number;
      }
     
      let plivoSipHeaders = {
          "X-Ph-Calleruserid": this.userId,
          "X-Ph-TagId": callData.tagId
        };
 
      this.plivoBrowserSdk.client.call("1" + callData.number, plivoSipHeaders);
    },

    // Handler function when the AddToCallDisplay component emits the 'dismount' event.
    async dismountAddToCall(payload) {
      if (payload) {
        try {
          await PlivoService.addNewCall(payload);
        } catch (err) {
          console.log(err);
        }
      }
      this.setCallBarDisplay("inCall"); // Setting display back to 'inCall.'
    },

    // Function that is the handler for the 'callPressed' event emitted by the DefaultDisplay component.
    defaultDisplayCall(number) 
    {
      console.log("default display call ", number);
      this.outboundCalleeDisplayTitle = number;

      let plivoSipHeaders = {
          "X-Ph-Calleruserid": this.userId,
          "X-Ph-TagId": 0
        };

      this.plivoBrowserSdk.client.call("1" + number, plivoSipHeaders);
    },
    // Function that is the handler for the 'contactCallPressed' event emitted by the DefaultDisplay component.
    contactFullnameNumber(calleeFirstName, calleeLastName, calleeNumber) {
      console.log('Inside Callbar ContactFullnameNumber ')
      
      // Populating the 'outboundCalleeDisplayTitle' field, which will be passed as a prop to the callingDisplay
      // component.
      if (calleeFirstName && calleeLastName) this.outboundCalleeDisplayTitle = calleeFirstName + " " + calleeLastName;
      else if (calleeFirstName) this.outboundCalleeDisplayTitle = calleeFirstName;
      else if (calleeLastName) this.outboundCalleeDisplayTitle = calleeLastName;
      else this.outboundCalleeDisplayTitle = calleeNumber;

      let plivoSipHeaders = {
          "X-Ph-Calleruserid": this.userId,
          "X-Ph-TagId": 0
        };

      // if the user is calling another endpoint
      if (calleeNumber.includes("@")) {
        // In this case that it is an endpoint to endpoint call, we will send the following headers,
        // where the caller headers correspond to the info of the user in the current browser,
        // and the callee headers correspond to the info of the user they are calling.
        //
        // For some reason, Plivo turns any header into capitalized text, where the first letter is
        // uppercase and the rest are lowercase, no matter what case you specify as the inital headers here.
        //
        // So for example if I did "X-PH-CallerUserID" as the header name, even though in the on-remote-ringing
        // the extra header would be displayed as "X-PH-CallerUserID," in the browser of the user receiving the call,
        // the header shows up as "X-Ph-Calleruserid," so here we might as well change the intial header to following
        // that casing format.
        //
        // Also, doesn't look like you can put a space in either the header name or header value, since that is not
        // a valid character to have in a url. That is why the 'Callerdisplaytitle' and 'Calleedisplaytitle' weren't
        // showing up before (because it had a space between the first name and the last name of the user).
        // The valid characters Plivo accepts are [A-Z], [a-z], and [0-9], and dashes (-) and parentheses also seem to
        // work even though according the Plivo documentation it doesn't say they are allowed.
        // My hypothesis is that any valid characters in a url are allowed, which is why dashes and parentheses worked.
        //
        // Also, all the header names must start with 'X-Ph-' which is why we have them like that below.
        //
        // Also, it seems as though the case (upper and lowercasing) of the header values are actually preserved,
        // it's just the header names which are automatically changed to capitalization casing.
        //
        // Here is the link to the Plivo documentation on SIP Headers in case you are interested.
        // https://www.plivo.com/docs/voice/use-cases/pass-custom-headers/node
        //
        // Anyways, here are the plivo headers we will send...
        this.plivoBrowserSdk.client.call(calleeNumber, plivoSipHeaders);
      }
      // Otherwise, the user is calling an external number.
      else {
        this.plivoBrowserSdk.client.call("1" + calleeNumber, plivoSipHeaders);
      }
    },
    // This is the function that executes when the 'IncomingCallDisplay' component emits the
    // 'incomingCallIgnored' event.
    handleIncomingCallIgnored() {
      this.setCallBarDisplay(this.getPrevCallBarDisplay);
      this.setPrevCallBarDisplay(null);
    },
    notifyMe() {
      // Checking if the browser supports notifications.
      if (!("Notification" in window)) alert("This browser does not support notifications!");
      // Checking whether notification permissions have already been granted.
      else if (Notification.permission === "granted") this.notificationsAllowed = true;
      // Otherwise, we need to ask the user for permission.
      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") this.notificationsAllowed = true;
        });
      }

      // At last, if the user has denied notifications, you
      // want to be respectful so there is no need to bother them any more.
    },

    // Gets all users and contacts and adds them to 'searchMasterList.'
    async initializeSearchMasterList() {
      await this.initializeUsers();
      await this.initializeContacts();
    },

    // Gets all the contacts and adds them to 'searchMasterList.'
    async initializeContacts() {
      try {
          await this.getContactTypes();
        await this.getContacts();
        await this.getTags();
        const offset = this.searchMasterList.length;

        this.contacts.forEach((contact, index) => {
          this.searchMasterList.push({
            id: index + offset,
            type: "contact",
            fullName:
              (contact.first_name || "") + (contact.first_name && contact.last_name ? ` ${contact.last_name}` : ""),
            ph1: contact.ph1,
            ph2: contact.ph2,
            ph3: contact.ph3,
            contact_id: contact.contact_id,
          });
        });
      } catch (err) {
        console.log(err);
      }
    },

    // Gets all the users and adds them to 'searchMasterList.'
    async initializeUsers() {
      try {
        let users = this.users;

        if (!users || !users.length) {
          await this.getUsers();
          users = this.users;
        }

        const offset = this.searchMasterList.length;

        users.forEach((user, index) => {
          // console.log("user ", user) 
          // console.log("Logging this");
          this.searchMasterList.push({
            id: index + offset,
            type: "user",
            fullName: (user.first_name || "") + (user.first_name && user.last_name ? ` ${user.last_name}` : ""),
            ph0:user.plivo_user + "@phone.plivo.com",
            ph1: user.ph1,
            ph2: user.ph2,
            ph3: user.ph3,
            // ph1: '1122334455',
            // ph2: '22336655445',
            // ph3: '5665456654',
            user_id: user.user_id,
          });
        });
      } catch (err) {
        console.log(err);
      }
    },
  },

  beforeMount() {
    this.notifyMe();
    this.initializeSearchMasterList();
  },

  beforeUnmount(){
    //Deregistering the event listeners for clean up
    EventBus.$off("onCalling", this.handleOnCalling);
    EventBus.$off("onCallRemoteRinging", this.handleOnCallRemoteRinging);
    EventBus.$off("onIncomingCall", this.handleIncomingCall);
    EventBus.$off("onCallAnswered", this.handleOnCallAnswered);
    EventBus.$off("onCallTerminated", this.handleOnCallTerminated);
    EventBus.$off("onCallFailed", this.handleOnCallFailed);
    EventBus.$off("onIncomingCallCanceled", this.handleOnIncomingCallCanceled);
    EventBus.$off("call-event", (callData) => {this.handleCallEvent(callData)});
  },
  
  created() {
    EventBus.$on("onCalling", this.handleOnCalling);
    EventBus.$on("onCallRemoteRinging", this.handleOnCallRemoteRinging);
    EventBus.$on("onIncomingCall", this.handleIncomingCall);
    EventBus.$on("onCallAnswered", this.handleOnCallAnswered);
    EventBus.$on("onCallTerminated", this.handleOnCallTerminated);
    // Event emitted when user cancels outbound call.
    EventBus.$on("onCallFailed", this.handleOnCallFailed);
    // Event emitted when caller cancels inbound call.
    EventBus.$on("onIncomingCallCanceled", this.handleOnIncomingCallCanceled);
    // this is an event emitted by other components to make calls
    EventBus.$on("call-event", (callData) => {this.handleCallEvent(callData)});
  },

};

 
</script>

<style scoped>
.callbar {
  height: 100%;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

