<template>
  <div id="app" style="margin: 5px; overflow-y: hidden;display:flex;">
    <button v-if="!isPanelVisible" @click="togglePanel" class="toggle-button" style="position: absolute; top: 80px;">
      <div style="margin: 5px;" >
        <v-icon size="36px" color="primary">mdi-menu</v-icon>
      <div><v-icon style="margin: 5px;" size="30px" color="primary">mdi-account-details</v-icon></div>
      <div><v-icon style="margin: 5px;" size="30px" color="primary">mdi-calendar-today</v-icon></div>
      <div><v-icon style="margin: 5px;" size="30px" color="primary">mdi-cloud-upload</v-icon></div>
      <div><v-icon style="margin: 5px;" size="30px" color="primary">mdi-chat</v-icon></div>
    </div>
    </button>
    <v-icon style="margin-top: 10px;" v-if="isPanelVisible" @click="togglePanel" size="26px" color="primary">mdi-arrow-right</v-icon>
    <RightPanel style="overflow-y: scroll;" v-if="isPanelVisible" />
  </div>
</template>

<script>
import RightPanel from '../components/FrontDesk/RightPanel/RightPanel.vue';
import { mapState,mapMutations } from 'vuex';

export default {
  name: 'Frontdesk Right Panel',
  components: {
    RightPanel,
  },
  computed:{
    ...mapState(['isPanelVisible']),
  },
  methods: {
    ...mapMutations(['changeRightPanelStatus']),
    togglePanel() {
      
      this.changeRightPanelStatus(!this.isPanelVisible);
      if (!this.isPanelVisible) {
        window.scrollTo(0, 0); // Scroll to top when hiding the panel
      }
    },
  },
};
</script>

<style>

</style>
