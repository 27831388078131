<template>
    <div>
      <v-data-table :headers="headers" :items="tags" item-value="tag_name" class="elevation-1" 
        :items-per-page-options="[5, 10, 15, 20]">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>TagSettings</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <!-- <v-checkbox v-model="can_manual_tagging" label="Enable Manual Tagging" style="margin-top: 2%;" color="success" @click="toggleSwitch" ></v-checkbox>
            <v-spacer></v-spacer> -->
            <v-btn color="green" @click="openAddTagDialog" :disabled="!can_manual_tagging">Create New Tag</v-btn>
            <v-spacer></v-spacer>
            <v-icon color="red" @click="closeTagSettings">mdi-close</v-icon>
          </v-toolbar>
        </template>
  
  
        <template v-slot:item.tag_name="{ item }">
          <div @click.prevent="editOrDeleteTag(item)"
            style="margin-left: 8px; text-decoration: none; color: black; cursor: pointer;">
            {{ item.tag_name }}
          </div>
        </template>
  
  
        <template v-slot:item.tag_icon="{ item }">
          <div @click.prevent="editOrDeleteTag(item)"
            style="margin-left: 8px; text-decoration: none; color: black;cursor: pointer;">
            <v-icon :color="item.tag_color">mdi-{{ item.tag_icon }}</v-icon>
          </div>
        </template>
  
        <template v-slot:item.enable_auto="{ item }">
          <v-switch v-model="item.enable_auto" color="success" @click="updateTagSwitch(item,'enable_auto')" ></v-switch>
        </template>
  
        <template v-slot:item.unlist="{ item }">
          <v-switch v-model="item.unlist" color="red" @click="updateTagSwitch(item,'unlist')"></v-switch>
        </template>

      </v-data-table>
  
      <!-- Tag create,edit and delete form -->
      <TypeTagForm v-if="dialog" v-model="dialog" @close="closeDialog" :isEdit="isTagEdit" :incoming_Tag="localTag"
        :contactType="null" />
  
    </div>
  </template>
  
  <script>
  import TypeTagForm from '../AdminSettings/TypeTagForm.vue';
  import { mapGetters, mapActions, mapMutations } from "vuex";
  export default {
    name: "TagSettings",
    data: () => ({
      headers: [
        { title: 'Tag Name', value: 'tag_name',align: 'center'},
        { title: 'Tag Icon', value: 'tag_icon',align: 'center'},
        { title: 'Enable Auto', value: 'enable_auto' },
        { title: 'Block Further Usage', value: 'unlist'},
      ],
      dialog: false,
      isTagEdit: false,
      localTag: {
        id: null,
        name: '',
        icon: '',
        color: '#FF2929',
        enable_auto: false,
        unlist: false
      },
    }),
    components: { TypeTagForm },
    computed: {
      ...mapGetters("tags", ["tags","can_manual_tagging"]),
      ...mapActions("tags", ["getTags"]),
      
    },
    methods: {
      ...mapMutations("tags",["manual_tagging"]),
      ...mapActions('tags', ["updateTag"]),
      // Enable And Disable Manual Tagging
      toggleSwitch(){
        this.manual_tagging(!this.can_manual_tagging);
      },
      openAddTagDialog() {
        this.dialog = true;
        this.resetNewTag(); // Reset fields for a new entry
        this.isTagEdit = false;
      },
  
      editOrDeleteTag(item) {
        this.isTagEdit = true;
        this.localTag = {
          id: item.tag_id,
          name: item.tag_name,
          icon: item.tag_icon,
          color: item.tag_color,
          enable_auto: item.enable_auto,
          unlist: item.unlist
        };
  
        this.dialog = true;
      },
      async updateTagSwitch(item, switch_){
        let tag = {
            tag_name: item.tag_name,
            tag_icon: item.tag_icon,
            tag_color: item.tag_color,
            enable_auto : item.enable_auto,
            unlist : item.unlist
          };
        if(switch_ === 'enable_auto'){

          tag.enable_auto = !tag.enable_auto;
        }else if(switch_ === 'unlist'){

          tag.unlist = !tag.unlist;
        }
        try {
            await this.updateTag({tag_data:tag, tag_id: item.tag_id});
          } catch (error) {
            console.error(error);
          }
      },
  
      resetNewTag() {
        this.localTag = {
          id: null,
          name: '',
          icon: '',
          color: '#FF2929',
          enable_auto: false,
          unlist: false
        };
      },
    //   Tag add, edit dialog 
      closeDialog() {
        this.dialog = false;
      },
      closeTagSettings(){
        this.$emit('closeTagSettings')
      }
    },
    async created(){
      if(this.tags){
        await this.getTags;
      }
    }
  };
  </script>
<style>

.v-data-table thead th {
  font-weight: bold !important; /* Make header text bold */
}
</style>