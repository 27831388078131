import * as Vue from 'vue';
import App from "@/App.vue";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import chartJSPluginZoom from "chartjs-plugin-zoom";
import store from "@/store/index.js";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import VueDraggableResizable from "vue-draggable-resizable";

// To Avoid "Failed to resolve component" warning
import UserPopup from './components/Avatar/UserPopup.vue';
import TypeTagForm from './components/AdminSettings/TypeTagForm.vue';
import MessagePopup from './components/Messenger/MessagePopup.vue'
import Message from './components/Messenger/Message.vue'
// import the vue virtual scroller
import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

// optionally import default styles
import "vue-draggable-resizable/dist/VueDraggableResizable.css";

// add moment as a global object
import moment from "moment";

// add the plivo browser sdk object as a global object
import plivoBrowserSdk from "@/plugins/plivo";
// try logging in to plivo (useful on page refresh)
store.dispatch("logInPlivoSDK");

const app = Vue.createApp(App)
app.config.globalProperties.plivoBrowserSdk = plivoBrowserSdk;
app.config.globalProperties.moment = moment;

app.use(router);
app.use(store);
app.use(vuetify);
app.use(chartJSPluginZoom);
app.use(VueVirtualScroller);

//------------------------------------------------------------------------
app.component("vue-draggable-resizable", VueDraggableResizable);

// We Reuse these components in multiple components
app.component("UserPopup",UserPopup);
app.component("TypeTagForm",TypeTagForm);
app.component("MessagePopup",MessagePopup);
app.component("Message",Message)
// Register a global custom directive called v-focus
app.directive("focus", {
  mounted: (el) => el.focus()
});

app.mount("#app");


(function(w,d,s,r,k,h,m){
        if(w.performance && w.performance.timing && w.performance.navigation) {
                w[r] = w[r] || function(){(w[r].q = w[r].q || []).push(arguments)};
                h=d.createElement('script');h.async=true;h.setAttribute('src',s+k);
                d.getElementsByTagName('head')[0].appendChild(h);
                (m = window.onerror),(window.onerror = function (b, c, d, f, g) {
                m && m(b, c, d, f, g),g || (g = new Error(b)),(w[r].q = w[r].q || []).push(["captureException",g]);})
        }
})(window,document,'//static.site24x7rum.com/beacon/site24x7rum-min.js?appKey=','s247r','61696df0fbbd8847ac675d202273ae75');