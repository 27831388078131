<template>
    <div>
      <iframe :src=fbpath style="display: flex; margin-left: 10%; border: 0px;"></iframe>
    </div>
  </template>
  
  <script>
  export default {
      name: "SocialMedia",
      data(){
          return{
              fbpath : process.env.VUE_APP_API_URL + "/fbpage.html"
          }
      }
      
  }
  </script>
  
  <style>
  
  </style>