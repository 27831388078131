<template>
  <v-card>
    <!-- complete button -->
    <v-tooltip location="top" text="Complete task">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" v-if="
          message.convo_mentions.length > 0 &&
          message.convo_mentions.some((m) => m.user_id === userId && !m.completed)
        " icon variant="text" style="width: 25px;height: 25px;" @click="completeTask()">
          <v-icon size="small">mdi-check-outline</v-icon>
        </v-btn>
      </template>
    </v-tooltip>
    <!-- reaction button -->
    <v-tooltip location="top" text="Add reaction (feature in development)">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" variant="text" style="width: 25px;height: 25px;" icon>
          <v-icon size="small">mdi-emoticon</v-icon>
        </v-btn>
      </template>
    </v-tooltip>
    <!-- save button -->
    <v-tooltip location="top" text="Save message (feature in development)">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" variant="text" style="width: 25px;height: 25px;" icon>
          <v-icon size="small">mdi-content-save</v-icon>
        </v-btn>
      </template>
    </v-tooltip>
    <v-tooltip location="top" text="Reply">
      <template v-slot:activator="{ props }">
        <v-btn @click="AddRef" v-bind="props" variant="text" style="width: 25px;height: 25px;" icon>
          <v-icon size="small">mdi-reply</v-icon>
        </v-btn>
      </template>
    </v-tooltip>
    <v-tooltip location="top" text="References">
      <template v-slot:activator="{ props }">
        <v-btn @click="seeRef" v-bind="props" variant="text" style="width: 25px; height: 25px;" icon>
          <v-icon size="small">mdi-chat</v-icon>
        </v-btn>
      </template>
    </v-tooltip>
    <v-dialog v-model="dialog" width="50%">
      <v-card>
        <v-card-title>References</v-card-title>
        <v-card-text>
          <div v-if="filteredMessage && filteredMessage.length > 0">
            <DynamicScroller :items="filteredMessage" :minItemSize="25" key-field="convo_message_id" style="height: 100%;"
              :buffer="20000" ref="scroller">
              <template v-slot="{ item, index, active }">
                <DynamicScrollerItem :item="item" :active="active">
                    
                 <div style="color: grey;" v-if="item  && item.message">
                  <v-icon @click="showRefMsg(item)" >mdi-hand-pointing-up</v-icon> {{ item.message }}
                </div> 
                 
                </DynamicScrollerItem>
              </template>
            </DynamicScroller>
          </div>
          <div v-else>
            No References for this message
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ConvoSerice from "@/services/convoService";
import { mapGetters } from "vuex";
import eventBus from "@/eventBus";
import { mapActions, mapMutations } from "vuex/dist/vuex.cjs.js";
import Message from "@/components/Messenger/Message";

export default {
  name: "MessagePopup",
  props: {
    message: { type: Object, default: null },
  },
  components: {
    Message,
  },
  data() {
    return {
      dialog: false,
      filteredMessage: null,
    };
  },
  computed: {
    ...mapGetters(["userId"]),
  },
  watch: {
    filteredMessage(newval){
      this.filteredMessage = newval
    }
   },
  methods: {
    ...mapMutations("convos", ["changeReferencedConvo"]),
    ...mapActions("convos", ["getMessages"]),
    async seeRef() {
      this.dialog = true;
      console.log("message", this.message.convo_id)
      let messages = await this.getMessages(this.message.convo_id)
      console.log("getMessages", messages)
      this.filteredMessage = messages.filter(msg => msg.ref === this.message.convo_message_id);
      console.log("filteredMessage", this.filteredMessage);
    },
    AddRef() {
      console.log("message:", this.message)
      this.changeReferencedConvo({
        convo_message_id: this.message.convo_message_id,
        message: this.message.message,
        convo_message_id: this.message.convo_message_id ? this.message.convo_message_id : null,
        media_id: this.message.convo_attachments ? this.message.convo_attachments.length > 0 ? this.message.convo_attachments[0].media_id : null : null
      })
      eventBus.$emit("referenced");
    },
     showRefMsg(item) {
       this.$emit('showRefMsg', item);

    },
    completeTask() {
      ConvoSerice.updateMentionStatus(this.message.convo_id, this.message.convo_message_id, { completed: true }).catch(
        (e) => {
          eventBus.$emit("alert", "Error completing task");
          console.log(e);
        }
      );
    },
    getReferenceMessage(ref, currentIndex) {
      if (ref === null) return null;
      if(this.filteredMessage){
        const referenceMessage = this.filteredMessage.find((message, idx) => {
        if (message.convo_message_id === ref) {
          message.index = idx; // Ensure index is included in the reference message
          return true;
        }
        return false;
      });
      return referenceMessage ? { ...referenceMessage, index: referenceMessage.index } : null;
      }
      else{
        return null;
      }
    },
    scrollToReference(refIndex) {
      console.log("called2")
      const element = document.getElementById(`message-${refIndex}`);
      console.log(refIndex)
      if (element) {
        element.style.backgroundColor = 'yellow';
        setTimeout(() => {
          element.style.backgroundColor = ''; // revert to original or default background color
        }, 1000);
        console.log("called3")
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>
