<template>
  <div>
    <!-- display the date if necessary -->
    <div v-if="active && displayDateHeader" class="text-caption separator mt-2 mb-1">
      <div class="date mb-1">
        {{ simplifyDate(message.date, "LL") }}
      </div>
    </div>
    <!-- display the message -->
    <div :class="'chat-bubble-container ' + (active && displayDateHeader ? 'mb-2' : '')" >
      <template v-if="message.contact_id !== 0">
        <v-menu
          :attach="'#bubble-' + message.comm_participant_id" 
          :disabled="/^\d{10}$/.test(contact.full_name)"
          open-on-hover
          :close-on-content-click="false"
          max-width=40
          min-width=20
          max-height=25
          location="top"
          close-delay=300
          open-delay=300
          z-index=3000
        >
          <template v-slot:activator="{ props }">
            <!--
              inbound -- incoming-call
              outbound -- outgoing-call
              incoming -- incoming-message (sms & mms)
              outgoing -- outgoing-message (sms & mms) 
              -->
            <div
              v-bind="props"
              v-bind:id="'bubble-' + message.comm_participant_id"
              :class="
                'chat-bubble ' +
                (message.comm_direction === 'inbound'
                  ? 'inbound'
                  : message.comm_direction === 'outbound'
                  ? 'outbound'
                  : message.comm_direction === 'incoming'
                  ? 'inbound message-incoming'
                  : message.comm_direction === 'outgoing' ||
                    message.comm_direction == 'delivered' ||
                    message.comm_direction == 'queued'
                  ? 'outbound'
                  : 'other-call')
              "
            >
                  <div class="icon-spacing">
                  <div
                    v-if="message.call_missed === 1"
                    style="color: red; display: flex; align-items: center"
                  >
                    <div style="color: black">
                      <v-icon size="20" v-if="message.phone_num == contact.ph1">
                        mdi-cellphone
                      </v-icon>
                      <v-icon size="20" v-else-if="message.phone_num == contact.ph2">
                        mdi-home
                      </v-icon>
                      <v-icon size="20" v-else-if="message.phone_num == contact.ph3">
                        mdi-briefcase
                      </v-icon>
                      <v-icon
                        size="20"
                        v-if="message.phone_num == contact.phone_num"
                      >
                        mdi-phone
                      </v-icon>
                      <v-icon
                        v-bind="props"
                        size="20"
                        v-if="message.call_missed === 1"
                        color="red"
                      >
                        mdi-call-missed
                      </v-icon>
                    </div>
                    <div>
                      <span v-if="message.comm_ref">
                        <audio
                          :src="recording(message.call_uuid)"
                          :class="
                            message.comm_direction === 'inbound'
                              ? 'inbound'
                              : 'outbound'
                          "
                          controls
                          preload="none"
                        />
                      </span>
                      <span v-else>
                        {{ "missed call" }}
                      </span>
                    </div>
                  </div>
                  <div v-else-if="message.call_missed === 2" style="color: green">
                    <v-icon size="20"> mdi-phone </v-icon>
                    {{ "Call is ringing...." }}
                  </div>
                  <div v-else-if="message.call_missed === 3" style="color: green">
                    <v-icon size="20"> mdi-phone </v-icon>
                    {{ "On going call" }}
                  </div>
                  <div v-else-if="message.text_message_id" :ref="setChatRefs">
                    <v-btn
                      v-bind="props"
                      fab
                      v-if="
                        message.comm_direction == 'failed' ||
                        message.comm_direction === 'undelivered' ||
                        message.comm_direction === 'rejected'
                      "
                      size="x-small"
                      @click="resendMessage(message.text_message_id)"
                    >
                      <v-icon color="red">mdi-refresh</v-icon>
                    </v-btn>
                    <div v-if="message.sentBy" class="user">
                      {{ message.sentBy }}
                    </div>
                    <div v-else class="user">
                      {{ phoneFormatTitle(contact.full_name) }}
                    </div>
                    <v-icon size="20" v-if="message.phone_num == contact.ph1">
                      mdi-cellphone
                    </v-icon>
                    <v-icon size="20" v-else-if="message.phone_num == contact.ph2">
                      mdi-home
                    </v-icon>
                    <v-icon size="20" v-else-if="message.phone_num == contact.ph3">
                      mdi-briefcase
                    </v-icon>
                    <v-icon size="20" v-else> mdi-phone </v-icon>
                    <span :ref="`text_message_${message.text_message_id}`" >{{
                      message.message
                    }}</span>
                  </div>
                  <div v-else>
                    <div style="display: flex">
                      <div class="contact">
                        {{ phoneFormatTitle(contact.full_name) }}
                      </div>
                      <div v-if="message.users" class="user">
                        {{ getUserNames([message.users[0]]) }}
                        <v-icon
                          v-if="message.users.length > 1"
                          small
                          @click="alert(getUserNames(message.users))"
                          color="grey darken-2"
                          >mdi-information</v-icon
                        >
                      </div>
                    </div>
                    <div style="color: red; display: flex; align-items: center">
                      <div>
                        <v-icon size="20" v-if="message.phone_num == contact.ph1">
                          smartphone
                        </v-icon>
                        <v-icon
                          size="20"
                          v-else-if="message.phone_num == contact.ph2"
                        >
                          home
                        </v-icon>
                        <v-icon
                          size="20"
                          v-else-if="message.phone_num == contact.ph3"
                        >
                          work
                        </v-icon>
                        <v-icon v-else size="20"> call </v-icon>
                      </div>
                      <div>
                        <span v-if="message.comm_ref">
                          <audio
                            :src="recording(message.call_uuid)"
                            controls
                            preload="none"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div v-if="message.count > 0">
                    <div v-for="(value, index) in message.media" :key="index">
                      <v-icon v-if="value.media_type == 'application/pdf'">
                        mdi-file-pdf-box
                      </v-icon>
                      <a
                        v-if="value.media_type == 'application/pdf'"
                        v-bind:href="
                          '/api/messages/text_message/' +
                          message.text_message_id +
                          '/media/' +
                          value.media_id
                        "
                        target="_blank"
                        >{{ value.media_url.split("/")[3].substring(15) }}</a
                      >

                      <v-img
                        v-on:click="
                          openImg(
                            getFileUrl(message.text_message_id, value.media_id)
                          )
                        "
                        v-if="value.media_type != 'application/pdf'"
                        max-height="150"
                        max-width="250"
                        :src="getFileUrl(message.text_message_id, value.media_id)"
                      ></v-img>
                      <video
                        :src="getFileUrl(message.text_message_id, value.media_id)"
                        controls
                        v-if="value.media_type == 'video/mp4'"
                        class="video-element"
                      ></video>
                    </div>
                  </div>
                  <div style="display: flex; align-items: center; justify-content: space-between; margin: 0.1em;">
                    <!-- Display Message Tags -->
                    <div style="display: inline-flex; gap: 0.1em; flex-wrap: wrap;">
                      <template v-for="tagId in message.tags" :key="tagId">
                        <template v-if="tagsHashnew[tagId]">
                          <v-tooltip :location="'bottom'" :text="tagsHashnew[tagId].tag_name">
                            <template v-slot:activator="{ props }">
                              <div v-bind="props" style="display: inline; margin: 0.1em;">
                                <v-btn size="18" color="white"  elevation="3" icon>
                                  <v-icon size="15" :color="tagsHashnew[tagId].tag_color" @click="removeTag(tagId)">
                                    mdi-{{ tagsHashnew[tagId].tag_icon }}
                                  </v-icon>
                                </v-btn>
                              </div>
                            </template>
                          </v-tooltip>
                        </template>
                      </template>
                    </div>

                    <!-- Display the message time -->
                    <div class="time" style="flex-shrink: 0;">
                        <a
                          v-if="
                            message.src_language == 'es' &&
                            message.text_message_id != null
                          "
                          style="color: blue"
                          :ref="`translate_${message.text_message_id}`"
                          @click="
                            translateMsg(
                              message.text_message_id,
                              message.originalMsg,
                              message.message
                            )
                          "
                          target="_blank"
                        >
                          show original
                        </a>
                        {{ simplifyDate(message.date, "LT") }}
                    </div>
                  </div>
                  </div>
                </div>
              </template>
              <div style="background-color: white; width:max-content;">
                <v-tooltip location="top" text="Create new convo on this item">
                  <template v-slot:activator="{ props: tooltip }">
                    <v-icon v-bind="tooltip" size="17" color="primary" @click="createInternalConvo">
                      mdi-plus
                    </v-icon>
                  </template>
                </v-tooltip>
                <v-tooltip location="top" text="View convos on this item">
                  <template v-slot:activator="{ props: tooltip }">
                    <v-icon v-bind="tooltip" style="margin-left: 5px" color="success" size="17" @click="showCommConvos">
                      mdi-chat
                    </v-icon>
                  </template>
                </v-tooltip>
                <v-tooltip v-if="can_manual_tagging" location="top" text="Apply Tag">
                  <template v-slot:activator="{ props: tooltip }">
                    <v-icon v-bind="tooltip" style="margin-left: 5px" color="red" size="17" @click="openTagPopup">
                      mdi-tag-plus
                    </v-icon>
                  </template>
                </v-tooltip>
              </div>
            </v-menu>
      </template>
      <template v-else>
        <div class="text-caption systemMessage">
          {{ message.message + " (" + simplifyDate(message.date, "LT") + ")" }}
        </div>
      </template>
    </div>
    <!-- Displaying List of Tags -->
    <div v-if="tagPopup" :style="{
          display: 'flex', 
          justifyContent: (message.comm_direction === 'inbound' || message.comm_direction === 'incoming')? 'flex-start' : 'flex-end', 
          position: 'relative',
          margin : '0.3rem'
        }"
    >
      <TagPopup 
        v-if="tagPopup" 
        :tags="filteredTags" 
        @applyTag="applyTag" 
        @closeMenu="tagPopup=false"
      />
    </div>
    <!-- insert space between each message -->
    <div style="font-size: 0.2rem">
      <br />
    </div>
  </div>
</template>

<script>
import { mapGetters,mapMutations } from "vuex";
import MiscService from "@/services/miscService";
import plivoApiService from "@/services/plivoService";
import EventBus from "@/eventBus";
import { phoneFormatTitle } from "@/util/util";
import TagPopup from "../TagPopup.vue";
import TagService from "@/services/tagService";
export default {
  data: () => ({
    items: [{ title: "Re-Send" }, { title: "Delete" }],
    chatRefs: [],
    tagPopup : false,
  }),
  name: "FDMessage",
  props: {
    message: { type: Object, default: null },
    prev: { type: Object, default: null },
    index: { type: Number, default: null },
    active: { type: Boolean, default: true },
    contact: { type: Object, default: null },
    highlight: { type: Boolean, default: false },
  },
  components:{
    TagPopup
  },
  computed: {
    ...mapGetters(["userId"]),
    ...mapGetters("people", ["getUserNames"]),
    ...mapGetters('tags',["tags","tagsHashnew","can_manual_tagging"]),
    displayDateHeader: function () {
      if (this.index == 0 || this.prev == null) return true;

      return (
        this.simplifyDate(this.message.date, "LL") !=
        this.simplifyDate(this.prev.date, "LL")
      );
    },
    translateRef() {
      return "translate_" + this.message.text_message_id;
    },
    filteredTags(){
      // Displaying only Un-Selected tags. Ignore selected tags and unlisted tags.
      const unselected = this.tags.filter(
        (tag) => !this.message.tags.includes(tag.tag_id) && !tag.unlist
      );
      return unselected;
    }
  },
  methods: {
    ...mapMutations(['changeRightPanelStatus']),
    phoneFormatTitle,
    setChatRefs(el) {
        if (el) {
          this.chatRefs.push(el);
        }
      },
    calculateNudge() {
      return (this.message.comm_direction === 'inbound' || this.message.comm_direction === 'incoming' ? -10 : 10);
    },
    isInbound() {
      return (this.message.comm_direction === 'inbound' || this.message.comm_direction === 'incoming');
    },
    createInternalConvo() {
      this.changeRightPanelStatus(true);
      EventBus.$emit("convoRef",this.chatRefs)
      EventBus.$emit("createConvo", this.message);
    },
    showCommConvos() {
      this.changeRightPanelStatus(true);
      EventBus.$emit("showCommConvos", this.message);
    },
    //Get the call recording file URL
    openImg: function (src) {
      window.open(src, "_blank");
    },
    alert: function (msg) {
      window.alert(msg);
    },
    recording: function (callUUID) {
      return MiscService.getCallRecordingURL(callUUID);
    },
    next() {
      alert("You clicked next!");
    },
    resendMessage(textMsgId) {
      console.log("clicked resend!" + textMsgId);
      return plivoApiService.resendMessage(textMsgId);
    },
    translateMsg(textMsgId, textMessage, translatedMsg) {
      let textInSpan = this.$refs["text_message_" + textMsgId].textContent;
      console.log("The text inside is :" + textInSpan);
      console.log(
        "The anchor text inside is :" +
          this.$refs["translate_" + textMsgId].textContent
      );
      if (textInSpan === textMessage) {
        this.$refs["text_message_" + textMsgId].textContent = translatedMsg;
        this.$refs["translate_" + textMsgId].textContent = "show original";
      } else {
        this.$refs["text_message_" + textMsgId].textContent = textMessage;
        this.$refs["translate_" + textMsgId].textContent = "show translated";
      }
      console.log(
        "we need to translate ," +
          textMsgId +
          " with orig msg as :" +
          textMessage +
          " translated msg as " +
          translatedMsg +
          " with action :" +
          JSON.stringify(this.$refs)
      );
      //MiscService.getText(true,textMsgId);
    },
    //Get the call Multimedia file URL
    getFileUrl: function (textMsgId, mediaId) {
      return MiscService.getMultimediaUrl(textMsgId, mediaId);
    },
    // the possible formats can be found at momentjs.com
    simplifyDate(date, format) {
      return this.moment(date).format(format);
    },
    // returns true if the two dates are within n minutes
    withinMinutes(date1, date2, n) {
      return this.moment(date1).diff(this.moment(date2), "minutes") <= n;
    },
    // splits the message by mentions
    splitbyMentions(message) {
      let splitIndices = [];
      message.convo_mentions.forEach((m) => {
        splitIndices.push(m.index_start);
        splitIndices.push(m.index_end);
      });
      let acc = -1;
      return [0, ...splitIndices].map((n, i, m) => {
        return {
          text: message.message.slice(n, m[i + 1]),
          user_id:
            i % 2 !== 0 ? message.convo_mentions[(acc += 1)].user_id : null,
          index: i,
          completed: i % 2 !== 0 ? message.convo_mentions[acc].completed : null,
        };
      });
    },
    openTagPopup(){
      this.tagPopup = !this.tagPopup;
    },
    async applyTag(tagId){
      let res;
      console.log("Selected Tag_id: "+tagId +"Conversation_id: "+ this.message.comm_participant_id);
      this.tagPopup = false;
      try {
        const payload = {
          conversation_id : this.message.comm_participant_id,
          tag_id : tagId
        };
        res = await TagService.addConversationTag(payload);
        if(res.status == 201){
          this.message.tags.push(tagId); 
          EventBus.$emit("alert", {
           message: "Tag Added Successfully",
           type: "success-snack",
         });
        }
        console.log("Adding Conversation Tag Res ",res);
      } catch (error) {
        EventBus.$emit("alert", {
           message: "Failed To Add Tag",
           type: "error-snack",
         });
        console.log("Adding Conversation Tag Res ",res);
        console.error(error);
        
      }
    },
    async removeTag(tagId){
      let res;
      console.log("Selected Tag_id: "+tagId +"Conversation_id: "+ this.message.comm_participant_id);
      try {
        res = await TagService.removeConversationTag(this.message.comm_participant_id, tagId);
        if(res.status == 200){
          this.message.tags = this.message.tags.filter((id) => id !== tagId);
          EventBus.$emit("alert", {
           message: "Tag Removed Successfully",
           type: "success-snack",
         });
        }
        console.log("Removing Conversation Tag Res ",res);
      } catch (error) {
        EventBus.$emit("alert", {
           message: "Failed To Remove Tag",
           type: "error-snack",
         });
        console.log("Removing Conversation Tag Res ",res);
        console.error(error);
      }
    }
  },
};
</script>

<style scoped>
.chat-bubble-container {
  white-space: pre-wrap;
  display: flex;
}

.chat-bubble {
  text-align: left;
  font-size: 0.9rem;
  width: auto;
  max-width: 60%;
  padding: 0.4rem 0.5rem;
  border-radius: 0.5rem;
}

.inbound {
  background-color: #e3e3e3;
  margin-left: 0.3rem;
  margin-right: auto;
}

.outbound {
  background-color: #d9f7dc;
  align-items: flex-end;
  align-content: flex-end;
  align-self: flex-end;
  margin-right: 0.3rem;
  margin-left: auto;
}

.other-call {
  align-items: center;
  align-content: center;
  align-self: center;
  margin-right: 0.3rem;
  margin-left: auto;
}

.message-incoming {
  margin-left: 0.3rem;
}

.systemMessage {
  flex: 1;
  color: #6e6e6e;
}

.separator {
  display: flex;
  align-items: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #e0e0e0;
}

.date {
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
}

.time {
  font-size: 0.6rem;
  text-align: right;
  color: #90a09f;
  flex-shrink: 0;
}

.user {
  font-size: 0.6rem;
  text-align: right;
  color: #90a09f;
  margin-left: auto;
}

.contact {
  font-size: 0.6rem;
  text-align: left;
  color: #90a09f;
}

.video-element {
  max-height: 150px;
  max-width: 250px;
}

.icon-spacing i {
  margin-left: 5px;
  margin-right: 5px;
}
</style>
