<template>
    <v-card 
        variant="plain"
        style="max-width: max-content; min-height: max-content; padding: auto;" 
        @mouseenter="stopCloseTimer" 
        @mouseleave="startCloseTimer"
        elevated="3"
    >
    <v-chip variant="outlined" color="success" style="padding: auto;">
        <div class="tag-container" ref="tagContainer">
        <div v-for="tag in tags" :key="tag.tag_id" style="display: inline;" class="tag-item">
            <v-tooltip :location="'bottom'" :text="tag.tag_name">
                <template v-slot:activator="{ props }">
                    <div v-bind="props" style="display: inline; padding: 0.2rem;">
                        <v-icon size="20" :color="tag.tag_color" @click="selectTag(tag.tag_id)" elevation="5">
                            mdi-{{ tag.tag_icon }}
                        </v-icon>
                    </div>
                </template>
            </v-tooltip>
        </div>
    </div>
    <div v-if="checkAdminRole && !isMassTag" style="opacity: 0.5;" @click="openTagSettings">
            <v-icon size="25" color="secondary">
                mdi-plus-circle</v-icon>
            <v-tooltip activator="parent" location="bottom">
               Add / Edit Tag</v-tooltip>
        </div>
    </v-chip>  
    </v-card>
</template>

<script>
import { mapGetters} from "vuex";
import EventBus from "@/eventBus";
export default {
    name: 'TagMenu',
    props: {
        tags: { type: Array, required: true },
        isMassTag : { type: Boolean, default : false}
    },
    data() {
        return {
            closeTimer: null,
            scrollLeft: 0
        };
    },
    computed:{
        ...mapGetters(['userRole']),
        // for now only admin can edit, delete,create contact type and tag.
        checkAdminRole(){
            return this.userRole.toLowerCase() === 'admin';
        },
    },
    methods: {
        selectTag(tag) {
            this.$emit('applyTag', tag);
        },
        // At Mouse Leave component, will close in 3sec 
        startCloseTimer() {
            if (!this.closeTimer && !this.isMassTag) {
                this.closeTimer = setTimeout(() => {
                    this.$emit('closeMenu');
                }, 2500);
            }
        },
        stopCloseTimer() {
            if (this.closeTimer) {
                clearTimeout(this.closeTimer);
                this.closeTimer = null;
            }
        },
        openTagSettings(){
            EventBus.$emit('open-tag-settings');
        },
        // Scroll with mouse wheel
        onScroll(event) {
            const container = this.$refs.tagContainer;
            if (!container) return;

            // differentiate track pad and mouse
            let isTrackpad = false;
            if (event.wheelDeltaY) {
              if (Math.abs(event.wheelDeltaY) !== 120) {
                isTrackpad = true;
              }
            }
            else if (event.deltaMode === 0) {
              isTrackpad = true;
            }

            // Horizontal scrolling behavior
            container.scrollLeft;
            container.scrollLeft += event.deltaY;

            // prevent parent scroll while using mouse wheel
            if (!isTrackpad) {
                event.preventDefault();
            }
        }
        
    },
    mounted() {
        const container = this.$refs.tagContainer;
        if (container) {
            container.addEventListener("wheel", this.onScroll, { passive: false });
        }
        this.startCloseTimer();
    },
    beforeUnmount() {
        const container = this.$refs.tagContainer;
        if (container) {
            container.removeEventListener("wheel", this.onScroll);
        }
        this.stopCloseTimer();
    },
};
</script>
<style scoped>
.tag-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 10rem; /* Adjust width as needed */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    -ms-overflow-style: none; /* Hide scrollbar in IE/Edge */
    scroll-behavior: smooth;
}

.tag-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome/Safari */
}

.tag-item {
    display: inline-block;
}
</style>